import React, { useState } from 'react';
import './Chat.css';
const conversationData = require('./conversation.json');

function Chat() {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [usedQuestions, setUsedQuestions] = useState(new Set());

  const typeMessage = (text, callback) => {
    let i = 0;
    const speed = 30; // speed in milliseconds
    let typedText = '';

    const typingInterval = setInterval(() => {
      if (i < text.length) {
        typedText += text.charAt(i);
        setMessages(msgs => msgs.map((m, idx) => 
          idx === msgs.length - 1 ? { ...m, response: typedText } : m
        ));
        i++;
      } else {
        clearInterval(typingInterval);
        callback();
      }
    }, speed);
  };

  const handleQuestionClick = (id) => {
    const newMessage = conversationData.conversation.find(msg => msg.id === id);
    if (newMessage && !isTyping) {
      setUsedQuestions(new Set(usedQuestions.add(id)));
      setIsTyping(true);
      setMessages([...messages, { user: newMessage.user, response: '' }]);
      typeMessage(newMessage.response, () => {
        setIsTyping(false);
        
      });
    }
  };

  return (
    <div className="chat-container">
      <div className="questions-bubble">
        {conversationData.conversation.map((msg) => (
          !usedQuestions.has(msg.id) && (
            <button
              className="question-button"
              key={msg.id}
              onClick={() => handleQuestionClick(msg.id)}
              disabled={isTyping}
            >
              {msg.user}
            </button>
          )
        ))}
      </div>

      <div className="message-feed">
        {messages.toReversed().map((msg, index) => (
          <div key={index} className="message" >
            <div> Q: {msg.user} </div>
            <div style={{paddingTop: "10px", fontSize: '.8em'}}>{msg.response}</div>
          </div>
        ))}
      </div>
      
    </div>
  );
}

export default Chat;
