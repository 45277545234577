import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

const Sketch = () => {
  const sketchRef = useRef();

  useEffect(() => {
    const currentRef = sketchRef.current;

    const sketch = (p) => {
      let angleOffset = 0; // This variable will control the wave effect

      p.setup = () => {
        p.createCanvas(p.windowWidth, p.windowHeight);
        p.stroke(0,0,255); // Set line color to black
      };

      p.draw = () => {
        p.background(255); // Set background color to white
        // Start drawing the sine wave outline
        p.beginShape();
      
        for (let x = -100; x < p.width+100; x += 12) { // Space lines 20 pixels apart
          // Calculate angle using the sine function
          let angle = p.cos(p.radians(x + angleOffset));
          p.push(); // Start a new drawing state
          p.translate(x, p.height-100); // Move to the starting point of each line
          p.rotate(angle); // Rotate by the calculated angle
          p.line(20, -50, 10, 50); // Draw the line
          p.pop(); // Restore original state
        }
        angleOffset += .8; // Modify the angle offset for the next frame
        
      };
      

      // Optional: Adjust canvas size when the window is resized
      p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
      };
    };

    new p5(sketch, currentRef);

    return () => {
      if (currentRef && currentRef.firstChild) {
        currentRef.removeChild(currentRef.firstChild);
      }
    };
  }, []); // Run once

  return <div ref={sketchRef} style={{}} />;
};

export default Sketch;
