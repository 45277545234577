// import logo from './logo.svg';
import './App.css';

import React from 'react';
import Sketch from './scripts/sketch';
import { Link } from 'react-router-dom';
import Chat from './components/Chat/Chat';
// import { start } from 'repl';

function Header() {
  return (
    <div style={{/*flex: '1'*/}}>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center'}}>
      <div className='Name'>TYLER LEWIS</div>
      <div className='SiteName'><Link to="/">fslfree.com</Link></div>
    </div>
    </div>
  )
}

function ContentBox({ children }) {
  return (
    <div style={{ /* height: '100%', maxWidth: '800px' */}}>
      <div className='ContentBox'>
        {children}
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <div className='ContentContainer'>
        <Header />
        <ContentBox >
          <div><a href="./TLewis_Resume_2024.pdf" target="_blank" rel="noopener noreferrer">Resume</a></div>
          <div><Link to="https://www.linkedin.com/in/tylew/" target="_blank" rel="noopener noreferrer">LinkedIn</Link></div>
          {/* <div><Link>Blog</Link></div> */}
          <div><Link to="https://github.com/tylew" target="_blank" rel="noopener noreferrer">Github</Link></div>
        </ContentBox>
        <ContentBox>
          <div style={{ width: '80vw', maxWidth: '700px' }}><Chat/></div>
        </ContentBox>
        
      </div>
      <Sketch />
      
    </div>
  );
}



export default App;
